var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-section", {
    attrs: { status: _vm.sectionStatus },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("sun-filter-layout", {
              attrs: {
                id: "filter-box",
                "filters-added": _vm.filterFind,
                "filters-available": _vm.availableFilters,
                "has-error": _vm.anyError,
                "show-reset": "",
              },
              on: {
                change: _vm.filtersSetFiltersFind,
                close: _vm.filtersResetErrors,
                remove: _vm.filtersResetErrors,
                reset: _vm.resetFilters,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "quickFilters",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "min-w-max" },
                          [
                            _c("sun-search-input", {
                              attrs: {
                                "class-input": "text-sm",
                                value: _vm.filterQuick["advertiser.name"],
                              },
                              on: {
                                search: function ($event) {
                                  return _vm.onQuickFiltersSearch(
                                    $event,
                                    "advertiser.name"
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: `filter.advertiser.name`,
                    fn: function ({ filter, onSelect, isDuplicate }) {
                      return [
                        _c("sun-input", {
                          attrs: {
                            type: "text",
                            "data-testid": "filter-input-name",
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                            enter: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _c("sun-data-table", {
              staticClass: "mt-2",
              attrs: {
                headers: _vm.headers,
                content: _vm.items,
                hoverable: "",
                loading: _vm.isLoading,
              },
              on: { sort: _vm.onSortTable },
              scopedSlots: _vm._u(
                [
                  {
                    key: `col.advertiser.name`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "sun-data-table-cell",
                          { staticClass: "font-bold min-width-cell" },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: `col.advertiser.activeProductsCount`,
                    fn: function ({ item }) {
                      return [
                        _c("sun-data-table-cell", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-col justify-start items-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: {
                                    "cursor-pointer": item.activeProductsCount,
                                  },
                                },
                                [
                                  _c(
                                    "sun-pill",
                                    {
                                      attrs: {
                                        color: item.activeProductsCount
                                          ? "indigo"
                                          : "red",
                                      },
                                    },
                                    [
                                      item.activeProductsCount
                                        ? _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: _vm.goToAnalytics(item),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.activeProductsCount
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _c("div", [_vm._v("0")]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "empty",
                    fn: function () {
                      return [
                        _c("asterix-no-data", { staticClass: "bg-white" }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            }),
            _c("sun-pagination-page", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isLoading,
                  expression: "!isLoading",
                },
              ],
              key: _vm.currentPage,
              staticClass: "my-6",
              attrs: {
                "total-pages": _vm.totalPages,
                "total-items": _vm.totalItems,
                "current-page": _vm.currentPage,
                value: _vm.itemsPerPage,
              },
              on: {
                changePage: function ($event) {
                  return _vm.goToPage({ page: $event })
                },
                changePerPage: _vm.changeItemsPerPage,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }