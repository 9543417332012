<template>
  <asterix-section :status="sectionStatus">
    <template #content>
      <sun-filter-layout
        id="filter-box"
        :filters-added="filterFind"
        :filters-available="availableFilters"
        :has-error="anyError"
        show-reset
        @change="filtersSetFiltersFind"
        @close="filtersResetErrors"
        @remove="filtersResetErrors"
        @reset="resetFilters"
      >
        <template #quickFilters>
          <div class="min-w-max">
            <sun-search-input
              class-input="text-sm"
              :value="filterQuick['advertiser.name']"
              @search="onQuickFiltersSearch($event, 'advertiser.name')"
            />
          </div>
        </template>
        <!--Filters Slot :Start -->
        <template #[`filter.advertiser.name`]="{ filter, onSelect, isDuplicate }">
          <sun-input
            type="text"
            data-testid="filter-input-name"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
          ></sun-input>
        </template>
      </sun-filter-layout>

      <sun-data-table
        :headers="headers"
        :content="items"
        hoverable
        :loading="isLoading"
        class="mt-2"
        @sort="onSortTable"
      >
        <template #[`col.advertiser.name`]="{ item }">
          <sun-data-table-cell class="font-bold min-width-cell">
            {{ item.name }}
          </sun-data-table-cell>
        </template>

        <template #[`col.advertiser.activeProductsCount`]="{ item }">
          <sun-data-table-cell>
            <div class="flex flex-col justify-start items-center">
              <div :class="{ 'cursor-pointer': item.activeProductsCount }">
                <sun-pill :color="item.activeProductsCount ? 'indigo' : 'red'">
                  <router-link v-if="item.activeProductsCount" :to="goToAnalytics(item)">
                    {{ item.activeProductsCount }}
                  </router-link>
                  <div v-else>0</div>
                </sun-pill>
              </div>
            </div>
          </sun-data-table-cell>
        </template>

        <template #empty>
          <asterix-no-data class="bg-white" />
        </template>
      </sun-data-table>

      <sun-pagination-page
        v-show="!isLoading"
        :key="currentPage"
        :total-pages="totalPages"
        :total-items="totalItems"
        :current-page="currentPage"
        :value="itemsPerPage"
        class="my-6"
        @changePage="goToPage({ page: $event })"
        @changePerPage="changeItemsPerPage"
      />
    </template>
  </asterix-section>
</template>

<script>
import { indexMixin } from '@/mixins/index/indexMixin';
import filtersMixin from '@/mixins/filters/filtersMixin';
import { getAdvertisers } from '@/services/modules/ThirdParty/advertiser';
import AsterixSection from '@/components/templates/AsterixSection';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import FILTERS_CONFIG from '@/model/modules/thirdParty/filtersConfig';
import { analytics as analyticsRouter } from '@/router/private/modules/thirdParty/analytics';
import Column from '@/model/shared/Column';

export default {
  name: 'AdvertiserList',
  components: {
    AsterixSection,
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    SunPill: () => import('@/components/atoms/SunPill/SunPill'),
  },
  mixins: [
    activeClientMixin,
    filtersMixin({
      filters: [FILTERS_CONFIG.ADVERTISER_NAME],
      filterQuick: {
        'advertiser.name': undefined,
      },
    }),
    indexMixin,
  ],
  data: () => ({
    headers: [
      new Column('NAME', 'advertiser.name').asSort(),
      new Column('ACTIVE PRODUCTS', 'advertiser.activeProductsCount').asSort().setClass('text-center'),
    ],
    items: [],
    isLoading: true,
  }),
  async mounted() {
    // load filters before api request
    await this.filtersLoadAllfiltersOnMounted();

    await this.getTableItems();
    await this.updateSortInURL();
  },
  methods: {
    async getTableItems() {
      this.isLoading = true;
      const { data, isCancel } = await this.getItemsFromAPI(this.getAdvertisers);
      this.items = data;
      if (!isCancel) {
        this.isLoading = false;
      }
    },
    getAdvertisers(params) {
      params.addInclude(['activeProductsCount']);
      params.addFilter('client.id', this.activeClient.id);
      return getAdvertisers(params);
    },
    goToAnalytics(item) {
      return {
        name: analyticsRouter.name,
        query: { [`filter[${FILTERS_CONFIG.ADVERTISER_ID.key}]`]: item.id },
      };
    },
  },
};
</script>

<style>
table tr td.min-width-cell {
  width: 50%;
}
</style>
